@font-face {
font-family: 'headingFont';
src: url(/_next/static/media/38d769c300458c9b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: 'headingFont Fallback';src: local("Arial");ascent-override: 91.40%;descent-override: 21.69%;line-gap-override: 0.00%;size-adjust: 107.45%
}.__className_0565e7 {font-family: 'headingFont', 'headingFont Fallback';font-weight: 500
}.__variable_0565e7 {--font-family-headline: 'headingFont', 'headingFont Fallback'
}

